body {
  font-family: 'Ubuntu';
}

.panel-transform-to .panel-heading {
  background-color: #AA9966;
}

.alert.alert-success  {
  background-color: #AA9966;
  border-color: #998855
}

/*Openlayers 3 */
.map{
  width:100%;
  height:400px;
  margin:0;
  padding:0;
}

/*Twitter typeahead*/
span.twitter-typeahead .tt-hint {
  color: #888
}

.btn-default {
  text-transform: none;
}
